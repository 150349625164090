import {
  Divider as BaseDivider,
  DividerProps as MuiDividerProps,
  SxProps,
  styled,
} from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';

import { UtilityProps } from '../../utils/prop';

type BaseProps = {};

const classes = {
  root: 'Divider--root',
};

export type DividerProps = Omit<MuiDividerProps, keyof BaseProps> & BaseProps & UtilityProps;

/** This component inherits [MUI Divider's API](https://mui.com/material-ui/api/divider/)\
 * See the [API documented on Storybook](https://design-system.cdn.drova.com/?path=/docs/elements-divider-props--documentation)
 */
export const Divider = styled(({ className, ...props }: DividerProps) => {
  return (
    <BaseDivider
      {...merge(
        {
          className: cls(className, classes.root),
        } satisfies DividerProps,
        props,
      )}
    />
  );
})(() => ({}) satisfies SxProps);

Divider.displayName = 'Divider';
